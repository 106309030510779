/* --------------------------------------------------------------- */
/* Variables                                                       */
/* --------------------------------------------------------------- */
:root {
  --box-shadow: 0px 0px 8px 4px rgba(0,0,0,0.03);
  --lc-repr-locations-list-width: 493px;
}

/* --------------------------------------------------------------- */
/* General                                                         */
/* --------------------------------------------------------------- */

* {
  font-family: aktiv-grotesk, sans-serif;
  font-size: 16px;
  box-sizing: border-box;
}

html {

  min-height: 100%;
  /*
  max-width: 100vw;
  display: flex;
*/
}

body {
  /*
  display: flex;
  flex: 1;
  max-width: 100vw; 
 */
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
}

#root {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
}

/* 
   This makes sure that the the element uses the available space
   when it's a flex positioned element. This is required to create
   the meeting rooms schedule; when you remove the `min-width: 0`
   the parent container (with flex) would expand it's size to fit
   it's children.
*/
.lc-form-right-panel,
.lc-form-container.rooms {
  min-width: 0; 
}

.lc-form-left-panel {
  min-width: 230px;
}

/* --------------------------------------------------------------- */
/* Side Panel                                                      */
/* --------------------------------------------------------------- */

.lc-sidepanel-page {
  display: flex;
  flex: 1;
  flex-direction: row;
}

.lc-sidepanel-bar {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 128;
  
  display: flex;
  flex-direction: column;

  padding: 48px;
  width: 420px;
  min-width: 420px;
}

.lc-sidepanel-bar .lc-clock {
  font-weight: bold;
  margin-top: auto;
  margin-bottom: 0;
  background-color: rgba(255, 255, 255, 0.2);
}

.lc-sidepanel-bar .lc-home-button {
  margin-bottom: 80px;
}

.lc-sidepanel-title {
  white-space: pre-line;
  font-size: 35px;
  line-height: 40px;
  color: white;
}

.lc-sidepanel-content {
  display: flex;
  margin-left: 420px;
}

/* --------------------------------------------------------------- */
/* Tree View                                                       */
/* --------------------------------------------------------------- */

.lc-tree-item > .lc-tree-item {
  padding: 0px;
  padding-left: 30px;
}

/* --------------------------------------------------------------- */
/* Form                                                            */
/* --------------------------------------------------------------- */

.lc-form-container {
  display: flex;
  flex-direction: row;
  flex: 1;
  background-color: rgb(247, 247, 247);
}

.lc-form-left-panel {
  box-sizing: border-box;
  width: 330px;
  background-color: gray;
  position: relative;
  padding-left: 50px;
  padding-top: 50px;
}


.lc-form-right-panel {
  flex: 1;
}

/* --------------------------------------------------------------- */
/* Home Screen                                                     */
/* --------------------------------------------------------------- */

.lc-home-screen-page {
  background-color: rgb(247, 247, 247);
  width: 100vw;
  height: 100vh;
}

.lc-home-screen-buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 78px;
}

.lc-home-screen-button-arrow {
  margin-left: auto;
}

.lc-home-screen-sidebar {
  background: url(img/home-screen-image.png) rgb(10, 30, 60) no-repeat top right;
}

.lc-home-screen-buttons h1 strong, 
.lc-home-screen-buttons h1 {
  font-size: 44px;
  color: rgb(85,87,89);
}

.lc-home-screen-buttons h1 {
  font-weight: normal;
  display: inline-block;
  border-left: 3px solid rgb(156, 219, 217);
  padding-left: 40px;
}

.lc-home-screen-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  height: 166px;
  width: 740px;
  margin-bottom: 30px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.1);
  background-color: white;
  color: rgb(85,87,89);
}

.lc-home-screen-button,
.lc-home-screen-button strong {
  font-size: 30px;
}

.lc-home-screen-button svg {
  margin-right: 40px;
}

.lc-home-representative-button {
  margin-top: 40px;
  margin-left: auto;
}

/* --------------------------------------------------------------- */
/* Representatives                                                 */
/* --------------------------------------------------------------- */

.lc-repr {
  display: flex;
  flex-direction: row;
  padding: 20px;
  border-radius: 30px;
  background-color: white;
  box-shadow: var(--box-shadow);
  margin-bottom: 30px;
  cursor: pointer;
  box-sizing: content-box;
  border: 3px solid transparent;
}

.lc-repr.active {
  border: 3px solid rgb(255, 130, 0);
}

.lc-repr-avatar {
  width: 120px;
  height: 120px;
  border-radius: 10px;
  margin-bottom: 15px;
  box-shadow: var(--box-shadow);
  box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
}

.lc-repr-state {
  box-sizing: border-box;
  width: 120px;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 18px;
  color: white;
  font-weight: bold;
  font-size: 14px;
  background-color: rgb(240, 75, 65);
}
  
.lc-repr-available {
  background-color: rgb(0, 170, 110);
}

/* Image column */
.lc-repr-col-img {
}

/* Content column */
.lc-repr-col-info {
  flex: 1;
  padding-left: 20px;
}

.lc-repr-name,
.lc-repr-department {
  margin: 0;
}

.lc-repr-name {
  font-size: 20px;
  margin-bottom: 15px;
  color: rgb(8, 31, 41)
}

.lc-repr-department {
  font-size: 18px;
  margin-bottom: 10px;
  color: rgb(85, 87, 89);
}

.lc-repr-topic {
  font-size: 14px;
}

/* Topics List */
.lc-repr-topic-list {
  margin: 0;
  padding: 0;
}

.lc-repr-topic {
  list-style: none;
  padding-top: 14px;
  padding-bottom: 14px;
  margin-right: 40px;
  border-bottom: 1px solid rgb(204, 204, 204);
  font-weight: 300;
  font-size: 14px;
  color: rgb(153, 153, 153);
}

.lc-repr-topic:last-child {
  border-bottom: none;
}

/* --------------------------------------------------------------- */
/* Registration Screen                                             */
/* --------------------------------------------------------------- */

.lc-register-page .lc-sidepanel-content {
  background-color: rgb(247, 247, 247);
  padding-top: 175px;
}

.lc-register-buttons {
  position: fixed;
  right: 90px;
  bottom: 40px;
}

.lc-repr-save-and-register-button:disabled,
.lc-register-button:disabled {
  background-color: rgba(0, 176, 185, 0.3) !important;
  color: white !important;
}

/* Logo at the bottom left of e.g. the registration form. */
.lc-beyond-limits {
  position: absolute;
  bottom: 50px;
  left: 50px;
  width: 108px;
  height: 50px;
  background-color: black;
  -webkit-mask: url(img/energy-beyond-limits.svg) no-repeat center;
  mask: url(img/energy-beyond-limits.svg) no-repeat center;
  mask-size: contain;
}

.lc-register-sidebar {
  background: url(img/register-visitor-panel-image.png) rgb(156, 219, 217) no-repeat top right;
}

.lc-register-required-message {
  color: rgb(244, 141, 59);
  font-size: 14px;
  text-align: center;
}

.lc-form-right-panel.register-visitor .lc-checkbox span {
  color: rgb(153, 153, 153);
}

/* --------------------------------------------------------------- */
/* Registration Screen: Badge Scan                                 */
/* --------------------------------------------------------------- */

.lc-register-page .lc-sidepanel-content {
  flex-direction: column;
  flex: 1;
}

.lc-badge-req-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  color: white;
  background-color: rgb(10, 30, 60);
  border-radius: 10px;
  margin-bottom: 22px;
  max-width: 648px;
}

.lc-badge-req-prompt {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding: 70px;
}

.lc-badge-api-progress {
  visibility: hidden;
}

.lc-badge-api-progress-visible {
  visibility: visible;
}

.lc-badge-req-container svg {
  margin-right: 76px;
}

.lc-badge-req-title {
  display: flex;
  flex-direction: column;
}

.lc-badge-req-title span {
  font-size: 26px;
}

.lc-badge-req-title strong {
  font-size: 34px;
  color: rgb(155, 220, 220);
}

.lc-badge-rows {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 50px;
}

.lc-badge-row {
  margin-bottom: 10px;
  font-size: 22px;
  font-weight: 500;
}

.lc-badge-details-edit {
  position: absolute;
  right: 46px;
  top: 40px;
  padding: 10px;
  color: white;
  display: inline block;
  text-decoration: underline;
  cursor: pointer;
}

/* --------------------------------------------------------------- */
/* Representatives Match Screen                                    */
/* --------------------------------------------------------------- */

/* Begin: additions Sept 2022 */

.lc-match-page .lc-sidepanel-bar {
  background: url(img/representative-match-panel-image.png) rgb(156, 219, 217) no-repeat top right;
}

.lc-match-page .lc-sidepanel-content {
  flex: 1;
  flex-direction: column;
  padding-right: var(--lc-repr-locations-list-width);
}

.lc-match-page {
  background-color: #f7f7f7;
}

.lc-repr-buttons-container {
  position: absolute;
  bottom: 98px;
  left: 70px;
  right: 60px;
  
  display: flex;
  flex-direction: column;
}

.lc-repr-locations-list {
  display: flex;
  flex-direction: column;
  flex: 1;
}
/* End: additions Sept 2022 */

.lc-form-right-panel.repr {
  padding-bottom: 100px;
}

.lc-repr-representatives {
  padding-left: 130px;
  padding-right: 139px;
  padding-top: 65px;
  min-height: 100vh;
}

.lc-repr-representatives-list .lc-repr:last-child {
  margin-bottom: 60px;
}

.lc-repr-locations {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  box-sizing: border-box;
  background-color: rgb(239, 239, 239);
  width: var(--lc-repr-locations-list-width);
}

.lc-repr-locations-list {
  margin-top: 75px;
  padding-left: 70px;
  padding-right: 60px;
}

.lc-location {
  border-radius: 10px;
  padding: 30px 20px;
  background-color: white;
  margin-bottom: 20px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  color: black;
  cursor: pointer;
  font-weight: bold;
  font-size: 20px;
}

.lc-location.active {
  background: url(img/checkbox-on-white-cleaned.svg) rgb(255, 130, 0) no-repeat 93% center;
  color: white;
  box-shadow: none;
}

.lc-repr-save-and-register-button {
  display: block;
  margin-bottom: 10px !important;
}

.lc-repr-reg-only-button {
  margin-bottom: 20px !important;
}

.lc-button {
  justify-content: space-between !important
}

/* --------------------------------------------------------------- */
/* Representatives schedule                                        */
/* --------------------------------------------------------------- */

/* Begin: additions Sept 2022 */
.lc-schedule-page {
  background-color: #efefef;
  min-height: 100vh;
}

.lc-schedule-page .lc-sidepanel-bar {
  background-color: rgb(8, 31, 44);
  color: white;
}

.lc-schedule-page .lc-sidepanel-content {
  flex-direction: column;
  padding-bottom: 40px;
  padding-right: 40px;
}

.lc-schedule-header-title,
.lc-schedule-rep {
  position: sticky;
  left: 440px;
}

.lc-schedule-line .lc-schedule-rep {
  background-color:  white;
}

.lc-schedule-header-title,
.lc-schedule-line:nth-child(even) .lc-schedule-rep {
  background-color: rgb(247, 247, 247);
}

.lc-schedule-header {
  top: 24px;
  z-index: 64;
}

.lc-schedule-fake-bg-vertical,
.lc-schedule-fake-bg-horizontal {
  background-color: rgb(239, 239, 239);
  position: sticky;
  z-index: 64;
}

.lc-schedule-fake-bg-vertical {
  padding: 10px;
  position: sticky;
  left: 420px;
}

.lc-schedule-header .lc-schedule-fake-bg-vertical {
  top: 154px;
}

.lc-schedule-fake-bg-horizontal {
  top: 0;
  height: 110px;
}

.lc-schedule-page strong,
.lc-schedule-page .lc-sidepanel-title {
  color: rgb(156, 219, 217);
}

.lc-schedule-page strong {
  font-size: 16px;
  opacity: 0.4;
  margin-bottom: 15px;
  display: inline-block;
}

.lc-schedule-page .lc-checkbox span {
  font-size: 14px;
  font-weight: 300;
}

/* End: additions Sept 2022 */

.lc-schedule {
  padding: 20px;
  background-color: rgb(239, 239, 239);
  min-height: 100vh; /* Make sure that the container stetches the full window; also when there are not people in the schedule. */
}

.lc-schedule-line {
  display: flex;
  flex: 1;
  background-color: white;
}

.lc-schedule-line:nth-child(even){
  background-color: rgb(247, 247, 247);
}

/* The container around the representative info + header. */
.lc-schedule-header-title,
.lc-schedule-rep {
  display: flex;
  min-width: 500px;
  flex: 1; /* This will grow the column with the representative and not the day columns as they have a max-width set. */
}

.lc-schedule-header-title,
.lc-schedule-rep {
  padding: 15px;
}

.lc-schedule-ampm {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  max-width: 230px;
}

.lc-schedule-avail {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.lc-schedule-header-title,
.lc-schedule-rep,
.lc-schedule-header-info,
.lc-schedule-avail:nth-child(even) {
  border-right: 4px solid rgb(239, 239, 239);
}

.lc-schedule-header-ampm div,
.lc-schedule-avail:nth-child(odd) {
  border-right: 1px solid rgb(239, 239, 239);
}

.lc-schedule-header {
  position: sticky;
  /* top: 0; */
  display: flex;
  background-color: rgb(247, 247, 247);
}

/* .lc-schedule.sticky .lc-schedule-header { */
/*   position: fixed; */
/*   top: 0; */
/* } */

.lc-schedule.sticky .lc-schedule-lines {
  padding-top: 63px;
}

.lc-schedule-header-info {
  display: flex;
  flex-direction: column;
  width: 230px;
  max-width: 230px;
  box-sizing: border-box;
}

.lc-schedule-header-date {
  text-align: center;
  padding-bottom: 15px;
}

.lc-schedule-header-date,
.lc-schedule-header-title {
  padding-top: 25px;
}

.lc-schedule-header-ampm {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  font-weight: normal;
}

.lc-schedule-header-ampm div {
  flex: 1;
  text-align: center;
  padding-bottom: 15px;
}

.lc-schedule-header-ampm div:nth-child(2) {
  border: none;
}

.lc-schedule-header {
  font-size: 20px;
  font-weight: bold;
}

/* --------------------------------------------------------------- */
/* Rooms Schedule                                                  */
/* --------------------------------------------------------------- */

.lc-manage-rooms-page {
  min-height: 100vh;
  min-width: 0;
}

.lc-manage-rooms-page .lc-sidepanel-bar {
  background: url(img/manage-meeting-rooms-panel-image.png) rgb(255, 130, 0) no-repeat top right;
}

.lc-manage-rooms-page .lc-sidepanel-content {
  flex: 1;
  min-width: 0; /* See <<flex-and-min-width>> */
  background-color: rgb(247, 247, 247);
}

.lc-manage-rooms-page .lc-form-right-panel {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 25px;
}

/* ====================== old ======================== */

.lc-form-container.rooms .lc-form-left-panel {
  background: url(img/manage-meeting-rooms-panel-image.png) rgb(255, 130, 0) no-repeat top right;
}

.lc-form-container.rooms .lc-form-right-panel {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 25px;
}

/* Fix which pushes the right panel content down when there is not enough space to show the meeting form. */
.lc-form-container.rooms .lc-form-right-panel.lc-slot-selected {
  min-height: 1024px;
}

.lc-rooms-header {
  padding-top: 20px;
  padding-right: 50px;
  padding-left: 35px;
}

.lc-rooms-content {
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  background-color: rgb(239, 239, 239);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.17);
  overflow: hidden; /* Sept 2022: this was disable in this commit 97446450, not sure why as it removed the rounded corners. */
}

.lc-rooms-room-row {
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 20px;
}

.lc-rooms-room-row strong {
  display: inline-block;
  padding: 30px 10px;
  border-left: 4px solid rgb(156, 219, 217);
}

/* Big content container */
.lc-rooms-slots {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: white;
}

/* This is the container that we scroll from left/right. */
.lc-rooms-slots-scroller {
  position: relative;
}

/* One row of slots */
.lc-rooms-slots-row {
  display: flex;
  flex-direction: row;
}

/* Container for the room slot. */
.lc-rooms-slot-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  min-width: 155px;
  min-height: 260px;
  border-right: 1px solid rgb(240, 240, 240);
  border-bottom: 3px solid rgb(239, 239, 239);
  cursor: pointer;
}v

/* The most right slot container per row */
.lc-rooms-slot-container:last-child {
  border-right: none;
}

.lc-rooms-slots-row:last-child .lc-rooms-slot-container {
  border-bottom: none;
}

/* One single rouned rect with slot info */
.lc-rooms-slot {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  width: 128px;
  height: 80px;
  border-radius: 8px;
  color:  rgb(0, 170, 110);
  border-left: 8px solid rgb(0, 170, 110);
  background-color: rgba(0, 170, 110, 0.2);
  user-select: none;
}

.lc-rooms-slot.booked {
  color: rgb(240, 75, 65);
  border-left: 8px solid rgb(240, 75, 65);
  background-color: rgba(240, 75, 65, 0.20);

}

/* Interactive elements in the header (buttons, filter) */
.lc-rooms-interaction {
  display: flex;
  flex-direction: row;
}

.lc-rooms-date-filter {
  margin-right: auto;
}

/* --------------------------------------------------------------- */
/* Rooms: used to set left and right sections  same height         */
/* --------------------------------------------------------------- */

/* VERSION 1 */
.lc-rooms-rooms {
  display: flex;
  flex-direction: column;
}

/* VERSION 1: The height for both the room entries and slot infos; must be the same to make sure they are aligned. */
.lc-rooms-slots-row,
.lc-rooms-room-row {
  flex: 1;
  /* min-height: 100px; */
  /* max-height: 100px; */
}

/* We have to make sure the rows have exactly the same size. */
.lc-rooms-slots-row,
.lc-rooms-room-row {
  box-sizing: border-box;
  max-height: 260px;
}

/* --------------------------------------------------------------- */
/* Meeting Form + Content/Info                                     */
/* --------------------------------------------------------------- */

/* 
  Make sure the room-content element is relative so the
  `lc-meeting-container` can take all it's space. This is the
  container which wraps around the slots, meeting-info popup and
  meeting form.
*/
.lc-rooms-content {
  position: relative;
}

.lc-meeting-container {
  position: fixed;  
  left: 440px;
  top: 25px;
  right: 25px;
  bottom: 25px;
  background-color: rgba(8, 31, 44, 0.95);
  border-radius: 10px;
  border-left: 15px solid rgb(0, 170, 110);
}

.lc-meeting-container.booked {
  border-left: 15px solid rgb(240, 75, 65);
}

.lc-meeting-content {
  padding-top: 130px;
  padding-left: 270px;
  padding-right: 270px;
}

.lc-meeting-interaction {
  margin-bottom: 50px;
}

.lc-meeting-description,
.lc-meeting-room-name {
  color: white;
}

.lc-meeting-room-name {
  font-size: 30px;
  font-weight: bold;
}

.lc-meeting-specs {
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 20px;
  font-size: 20px;
}

.lc-meeting-description {
  display: inline-block;
  border-left: 2px solid rgb(156, 219, 217);
  font-size: 60px;
  padding-left: 20px;
}

.lc-meeting-duration,
.lc-meeting-organizer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 0;
}

.lc-meeting-spec-icon {
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#lc-meeting-close-icon {
  position: absolute;
  right: 50px;
  top: 50px;
}

.lc-meeting-spec-duration,
.lc-meeting-spec-bookedby strong,
.lc-meeting-spec-bookedby {
  font-size: 20px;
}

.lc-meeting-spec-bookedby {
  font-weight: 300;
}

/* --------------------------------------------------------------- */
/* Meeting Form                                                    */
/* --------------------------------------------------------------- */

.lc-meeting-form .el-room {
  width: 300px;
  margin-bottom: 35px;
}

.lc-meeting-form label {
  color: rgba(255, 255, 255, 0.5);
  white-space: nowrap;
  font-size: 20px;
  font-weight: 300;
}

.lc-meeting-form .ampm {
  align-items: center;
  width: 400px;
}

.lc-meeting-form .booked-by {
  width: 700px;
  align-items: center;
}

.lc-meeting-form .ampm,
.lc-meeting-form .booked-by {
  margin-bottom: 20px;
}

.lc-meeting-form .el-save-buttons {
  margin-top: 20px;
}
